import angular from "angular";

angular
    .module('ui-sentinel.login')
    .config(routes);

routes.$inject = ['$stateProvider'];

function routes($stateProvider) {
    $stateProvider
        .state('login', {
            url: '/login',
            template: require('./login.html'),
            params: {
                passwordChanged: false
            },
            data: {
                authorizationRequired: false
            }
        })
        .state('forgot', {
            url: '/forgot',
            template: require('./forgot.html'),
            data: {
                authorizationRequired: false
            }
        })
        .state('reset', {
            url: '/reset',
            template: require('./reset.html'),
            data: {
                authorizationRequired: false
            }
        });
}
