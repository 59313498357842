import angular from "angular";

angular
    .module('ui-sentinel.logins')
    .config(routes);

routes.$inject = ['$stateProvider'];

function routes($stateProvider) {
    $stateProvider
        .state('logins', {
            abstract: true,
            url: '/logins',
            template: '<ui-view/>',
            data: {
                authorizationRequired: true,
                pageTitle: 'Logins',
                subTitle: null,
                parentState: null
            }
        })
        .state('logins.list', {
            url: '/list',
            template: require('./logins-list.html')
        })
        .state('logins.username', {
            url: '/changeusername',
            template: require('./logins-username.html'),
            params: {
                loginId: null,
                referrer: 'home',
                referrerParams: null
            },
            data: {
                pageTitle: 'Change My Username',
                subTitle: null,
                parentState: null
            }
        })
        .state('logins.password', {
            url: '/changepassword',
            template: require('./logins-password.html'),
            params: {
                referrer: 'home',
                referrerParams: null
            },
            data: {
                pageTitle: 'Change My Password',
                subTitle: null,
                parentState: null
            }
        })
        .state('logins.termofservice', {
            url: '/termofservice',
            template: require('./logins-termofservice.html'),
            params: {
                referrer: 'home',
                referrerParams: null
            },
            data: {
                pageTitle: 'Accept Terms of Service',
                subTitle: null,
                parentState: null
            }
        });
}
