import angular from "angular";

angular
    .module('ui-sentinel.sentry-reports')
    .config(routes);

routes.$inject = ['$stateProvider'];

function routes($stateProvider) {
    $stateProvider
        .state('sentry-reports', {
            abstract: true,
            url: '/sentry-reports',
            template: '<ui-view/>',
            data: {
                authorizationRequired: true,
                pageTitle: 'Sentry Reports',
                subTitle: null,
                parentState: null
            }
        })
        .state('sentry-reports.latest', {
            url: '/latest',
            template: require('./latest-sentry-reports-list.html')
        })
        .state('sentry-reports.by-device', {
            url: '/{imei}',
            template: require('./sentry-reports-by-device.html'),
            params: {
                report: null,
                view: null,
                to: null,
                from: null
            },
            data: {
                subTitle: 'By Device'
            }
        })
        .state('sentinel-reports-by-list', {
            url: '/sentinels/bylist',
            template: require('./sentinel-reports-by-list.html'),
            data: {
                pageTitle: 'Check Sentinel',
                subTitle: null
            }
        })
        .state('sentry-reports-by-list', {
            url: '/sentrys/bylist',
            template: require('./sentry-reports-by-list.html'),
            data: {
                pageTitle: 'Check Sentry',
                subTitle: null
            }
        });
}
