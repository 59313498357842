jQuery.fn.table2CSV = function (options) {
    let myData;
    options = jQuery.extend({
            separator: ',',
            exclude: ".noExl",
            header: [],
            headerSelector: 'th',
            columnSelector: 'td',
            delivery: 'download', // popup, value, download
            filename: 'file', // filename to download
            transform_gt_lt: true // make &gt; and &lt; to > and <
        },
        options);

    const csvData = [];
    const el = this;

    //header
    const numCols = options.header.length;
    const tmpRow = []; // construct header avalible array

    if (numCols > 0) {
        for (let i = 0; i < numCols; i++) {
            tmpRow[tmpRow.length] = formatData(options.header[i]);
        }
    } else {
        $(el).filter(':visible').find(options.headerSelector).not(options.exclude).each(function () {
            if ($(this).css('display') !== 'none') tmpRow[tmpRow.length] = formatData($(this).html());
        });
    }

    row2CSV(tmpRow);

    // actual data
    $(el).find('tr').not(options.exclude).each(function () {
        const tmpRow = [];
        $(this).filter(':visible').find(options.columnSelector).not(options.exclude).each(function () {
            if ($(this).css('display') !== 'none') tmpRow[tmpRow.length] = formatData($(this).html());
        });
        row2CSV(tmpRow);
    });
    if (options.delivery === 'popup') {
        myData = csvData.join('\n');
        if (options.transform_gt_lt) {
            myData = sinri_recover_gt_and_lt(myData);
        }
        return popup(myData);
    } else if (options.delivery === 'download') {
        myData = csvData.join('\n');
        if (options.transform_gt_lt) {
            myData = sinri_recover_gt_and_lt(myData);
        }
        console.log("download csv");
        /*var url='data:text/csv;charset=utf8,' + encodeURIComponent(mydata);
        window.open(url);*/
        const isIE = /*@cc_on!@*/!!document.documentMode; // this works with IE10 and IE11 both :)
        //if (typeof msie !== "undefined" && msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))      // this works ONLY with IE 11!!!
        if (isIE) {
            console.log("is IE");
            let fullTemplate = '';
            if (typeof Blob !== "undefined") {
                //use blobs if we can
                fullTemplate = [myData];
                //convert to array
                const blob1 = new Blob(fullTemplate, { type: "text/html" });
                window.navigator.msSaveBlob(blob1, getFileName(options));
            } else {
                //otherwise use the iframe and save
                //requires a blank iframe on page called txtArea1
                txtArea1.document.open("text/html", "replace");
                txtArea1.document.write(fullTemplate);
                txtArea1.document.close();
                txtArea1.focus();
                sa = txtArea1.document.execCommand("SaveAs", true, getFileName(options));
            }

        } else {
            console.log("isNot IE");
            const blob = new Blob([myData], { type: "application/vnd.ms-excel" });
            window.URL = window.URL || window.webkitURL;
            const link = window.URL.createObjectURL(blob);
            a = document.createElement("a");
            a.download = getFileName(options);
            a.href = link;

            document.body.appendChild(a);

            a.click();

            document.body.removeChild(a);
        }

        return true;
    } else {
        myData = csvData.join('\n');
        if (options.transform_gt_lt) {
            myData = sinri_recover_gt_and_lt(myData);
        }
        return myData;
    }

    function sinri_recover_gt_and_lt(input) {
        let regexp = new RegExp(/&gt;/g);
        input = input.replace(regexp, '>');
        regexp = new RegExp(/&lt;/g);
        input = input.replace(regexp, '<');
        return input;
    }

    function row2CSV(tmpRow) {
        const tmp = tmpRow.join(''); // to remove any blank rows
        // alert(tmp);
        if (tmpRow.length > 0 && tmp !== '') {
            const mystr = tmpRow.join(options.separator);
            csvData[csvData.length] = mystr;
        }
    }

    function getFileName(options) {
        return (options.filename ? options.filename + '.csv' : "file.csv");
    }

    function formatData(input) {
        // replace " with “
        let regexp = new RegExp(/["]/g);
        let output = input.replace(regexp, "“");
        // HTML
        regexp = new RegExp(/\<[^\<]+\>/g);
        output = output.replace(regexp, "");
        output = output.replace(/&amp;/gi, '&');
        output = output.replace(/(&nbsp;|\r|\n)/gi, ' ');
        output = output.replace(/ +/gi, ' ');
        if (!output) {
            return '';
        }
        return '"' + output.trim() + '"';
    }

    function popup(data) {
        const generator = window.open('', 'csv', 'height=400,width=600');
        generator.document.write('<html><head><title>CSV</title>');
        generator.document.write('</head><body >');
        generator.document.write('<textArea cols=70 rows=15 wrap="off" >');
        generator.document.write(data);
        generator.document.write('</textArea>');
        generator.document.write('</body></html>');
        generator.document.close();
        return true;
    }
};
