import angular from "angular";
import $ from 'jquery';
import 'metismenu';

angular
    .module('sentinel.gateway.web')
    .controller('SentinelUiController', SentinelUiController);

////////////////////////////
SentinelUiController.$inject = ['$rootScope', 'localStorageService'];

function SentinelUiController($rootScope, localStorageService) {
    var vm = {
        isRlsRoute: isRlsRoute,
    };
    init();
    return vm;

    function init() {
        angular.element(document).ready(function () {
            $('#menu, #rlsmenu').metisMenu();
        });
    }

    function isRlsRoute() {
        return localStorageService.get('isRlsRoute');
    }
}
