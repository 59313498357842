import angular from "angular";

angular
    .module('ui-sentinel.fulfillment')
    .config(routes);

routes.$inject = ['$stateProvider', 'USER_ROLES'];

function routes($stateProvider, USER_ROLES) {
    $stateProvider
        .state('fulfillment', {
            abstract: true,
            url: '/fulfillment',
            template: '<ui-view/>',
            data: {
                authorizationRequired: true,
                authorizedRoles: [
                    USER_ROLES.systemAdmin,
                    USER_ROLES.supportAdmin,
                    USER_ROLES.supportObserver
                ],
                pageTitle: 'Fulfillment',
                subTitle: null,
                parentState: null
            }
        })
        .state('fulfillment.validate', {
            url: '/validate',
            template: require('./fulfillment-validate.html')
        })
        .state('fulfillment.config', {
            url: '/config',
            template: require('./fulfillment-config.html')
        })
        .state('fulfillment.import', {
            url: '/import',
            template: require('./fulfillment-import.html')
        });
}
