import angular from "angular";
import Cookies from 'js-cookie';
import {parseDomain, ParseResultType} from "parse-domain";

angular
    .module('ui-sentinel.session')
    .factory('SentinelUiSession', SentinelUiSession);

SentinelUiSession.$inject = ['$rootScope', 'ApiToken', 'LoginsApiService', 'AccountApiService', 'localStorageService', 'USER_ROLES', 'TrackingConfigService'];

function SentinelUiSession($rootScope, ApiToken, LoginsApiService, AccountApiService, localStorageService, USER_ROLES, TrackingConfigService) {
    const service = {
        user: null,
        focus: null,
        mode: 'account',
        create,
        setFocus,
        set,
        clear,
        isValid,
        isExpired,
        rootDomainForCookie,
        initFromCookie,
        isEulaAgreement,
        store,
        load,
        isReloaded: false,
        showShipmentModule,
        showWarehouseModule,
        showCalibrationModule,
        showPivotModule
    };

    return service;

    ///////////////////////////

    function create(tokenResponse, onSuccessFn, onErrorFn) {
        clear();

        ApiToken.set(tokenResponse.access_token, tokenResponse.token_type, ApiToken.getExpirationDate(tokenResponse.expires_in));
        if (!ApiToken.isValid()) {
            return;
        }

        saveAuthTokenCookie(tokenResponse);

        const currentLoginPromise = LoginsApiService.getCurrent().$promise;
        currentLoginPromise.then(
            function (login) {
                const currentAccountPromise = AccountApiService.getAccount().$promise;
                currentAccountPromise.then(
                    function (account) {
                        let isExpired = false;
                        if (login.dateLockoutExpiresTimestamp && !login.role.startsWith("api")) {
                            const timeDiff = (login.dateLockoutExpiresTimestamp * 1000) - new Date().getTime();
                            if (timeDiff < 0)
                                isExpired = true;
                        }
                        const eulaAgreement = $rootScope.isOrs ? account.eulaAgreement : login.eulaAgreement;
                        service.user = {
                            id: login.id,
                            name: login.userName,
                            role: login.role,
                            isExpired: isExpired,
                            isAnAdmin: login.role === 'api-admin' || login.role === 'api-login' || login.role === USER_ROLES.systemAdmin || login.role === USER_ROLES.accountAdmin || login.role === USER_ROLES.supportAdmin,
                            isSystemAdmin: login.role === 'api-admin' || login.role === USER_ROLES.systemAdmin,
                            isSupportAdmin: login.role === USER_ROLES.supportAdmin,
                            isSupportObserver: login.role === USER_ROLES.supportObserver,
                            isAccountAdmin: login.role === 'api-login' || login.role === USER_ROLES.accountAdmin,
                            isAccountEditor: login.role === USER_ROLES.accountEditor,
                            isAccountObserver: login.role === USER_ROLES.accountObserver,
                            accountId: account.id,
                            accountName: account.name,
                            accountAccessLevel: account.accessLevel,
                            accountIsTopParent: !account.parentId,
                            accountIsSubAccount: !!account.parentId,
                            isEulaAgreement: !!eulaAgreement,
                            shipmentTrackingPrefix: account.shipmentTrackingPrefix
                        };

                        service.focus = {
                            id: account.id,
                            name: account.name,
                            accessLevel: account.accessLevel,
                            isTopParent: !account.parentId,
                            isSubAccount: !!account.parentId,
                            shipmentTrackingPrefix: account.shipmentTrackingPrefix
                        };
                        loadTrackingConfig();


                        store();
                        if (onSuccessFn) {
                            onSuccessFn();
                        }
                    },
                    function (error) {
                        console.log(error);
                        if (onErrorFn) {
                            onErrorFn();
                        }
                    }
                );
            },
            function (error) {
                console.log(error);
                if (onErrorFn) {
                    onErrorFn();
                }
            });
    }

    function set(user, focus) {
        service.user = user;
        if (user) {
            //service.user.isAnAdmin = user && user.role === 'api-admin';
            service.user = angular.extend(user, {
                isAnAdmin: user.role === 'api-admin' || user.role === 'api-login',
                isSystemAdmin: user.role === USER_ROLES.systemAdmin,
                isSupportAdmin: user.role === USER_ROLES.supportAdmin,
                isSupportObserver: user.role === USER_ROLES.supportObserver,
                isAccountAdmin: user.role === USER_ROLES.accountAdmin,
                isAccountEditor: user.role === USER_ROLES.accountEditor,
                isAccountObserver: user.role === USER_ROLES.accountObserver,
            });
        }
        service.focus = focus;
        loadTrackingConfig();
        localStorageService.set('user', service.user);
        localStorageService.set('focus', service.focus);
    }

    function setFocus(account) {
        service.focus = {
            id: account ? account.id : service.user.accountId,
            name: account ? account.name : service.user.accountName,
            accessLevel: account ? account.accessLevel : service.user.accountAccessLevel,
            isTopParent: account ? !account.parentId : service.user.accountIsTopParent,
            isSubAccount: account ? !!account.parentId : service.user.accountIsSubAccount,
            shipmentTrackingPrefix: account ? account.shipmentTrackingPrefix : service.user.shipmentTrackingPrefix,
            isFocused: account && account.id !== service.user.accountId
        };
        loadTrackingConfig();
        localStorageService.set('focus', service.focus);
    }

    function store() {
        const authToken = ApiToken.get();
        localStorageService.set('token', authToken.token);
        localStorageService.set('tokenType', authToken.type);
        localStorageService.set('tokenExpirationTime', authToken.expiresAt);
        localStorageService.set('user', service.user);
        localStorageService.set('focus', service.focus);
    }

    function load() {
        ApiToken.set(
            localStorageService.get('token'),
            localStorageService.get('tokenType'),
            new Date(localStorageService.get('tokenExpirationTime')));

        service.user = localStorageService.get('user');
        service.focus = localStorageService.get('focus');
        service.isReloaded = true;
    }

    function clear() {
        ApiToken.clear();
        service.user = null;
        service.focus = null;
        service.isReloaded = false;
        localStorageService.clearAll();
    }

    function rootDomainForCookie() {
        const hostname = window.location.hostname;
        if (hostname.match(/\.qa\.organ-recovery\.com$/)) {
            return 'qa.organ-recovery.com';
        }
        const parseResult = parseDomain(hostname);
        if (parseResult.type === ParseResultType.Listed) {
            const { domain, topLevelDomains } = parseResult;
            return [domain].concat(topLevelDomains).join('.');
        }
        return hostname;
    }

    function saveAuthTokenCookie(tokenResponse) {
        if (!$rootScope.isOrs) {
            return;
        }
        const loginTime = new Date().getTime() / 1000;
        const loginExpiresTime = loginTime + tokenResponse.expires_in;
        const expires = tokenResponse.expires_in / 60 / 60 / 24;
        const domain = rootDomainForCookie();
        const cookie = {
            tokenResponse,
            loginTime,
            loginExpiresTime
        };
        const path = '';
        const secure = true;
        const sameSite = 'None';
        Cookies.set('oa_auth', JSON.stringify(cookie), { domain, path, secure, sameSite, expires });
    }

    function initFromCookie() {
        return new Promise((resolve, reject) => {
            const lsToken = localStorageService.get('token');
            if (!lsToken) {
                // check for the auth_token cookie
                const oaAuthCookie = Cookies.get('oa_auth');
                if (oaAuthCookie) {
                    const onSuccessFn = () => {
                        resolve();
                    };
                    const onErrorFn = () => {
                        resolve();
                    };
                    try {
                        const oaAuth = JSON.parse(oaAuthCookie);
                        if (oaAuth && oaAuth.tokenResponse && oaAuth.tokenResponse.access_token && oaAuth.loginExpiresTime) {
                            const tokenResponse = oaAuth.tokenResponse;
                            // recalculate the expires_in value
                            const expiresIn = Math.floor(oaAuth.loginExpiresTime - ((new Date()).getTime() / 1000));
                            if (expiresIn <= 0) {
                                resolve();
                                return;
                            }
                            tokenResponse.expires_in = expiresIn.toString();
                            this.create(tokenResponse, onSuccessFn, onErrorFn);
                        } else {
                            resolve();
                        }
                    } catch (e) {
                        resolve();
                    }
                    return;
                }
            }
            resolve();
        });
    }

    function isValid() {
        if (!service.user || !service.focus) {
            load();
        }

        return ApiToken.isValid() && service.user && service.focus;
    }

    function isExpired() {
        if (!service.user || !service.focus) {
            load();
        }
        return ApiToken.isValid() && service.user && service.user.isExpired;
    }

    function isEulaAgreement() {
        if (!service.user || !service.focus) {
            load();
        }
        return ApiToken.isValid() && service.user && service.user.isEulaAgreement;
    }

    function showShipmentModule() {
        console.log("showShipmentModule");
        if (service.user) {
            if (service.user.isSystemAdmin && (service.focus.id === service.user.accountId)) {
                return true;
            } else {
                return _.find(service.focus.accessLevel, function (role) {
                    return role === "Shipment";
                });
            }
        }

        return false;
    }

    function showWarehouseModule() {
        if (service.user) {
            if (service.user.isSystemAdmin && (service.focus.id === service.user.accountId))
                return true;
            else {
                return _.find(service.focus.accessLevel, function (role) {
                    return role === "Warehouse";
                });
            }
        }

        return false;
    }

    function showCalibrationModule() {
        if (service.user) {
            if (service.user.isSystemAdmin && (service.focus.id === service.user.accountId))
                return true;
            else {
                return _.find(service.focus.accessLevel, function (role) {
                    return role === "Calibration";
                });
            }
        }

        return false;
    }

    function showPivotModule() {
        if (service.user) {
            if (service.user.isSystemAdmin && (service.focus.id === service.user.accountId))
                return true;
            else {
                return _.find(service.focus.accessLevel, function (role) {
                    return role === "Pivot";
                });
            }
        }

        return false;
    }

    function loadTrackingConfig() {

        const promise = TrackingConfigService.getConfig(service.focus).$promise;
        promise.then(
            function (result) {
                if (result) {
                    service.focus.trackingConfig = { "referencePrefix": result.shipmentReferencePrefix };
                    store();
                } else
                    service.focus.trackingConfig = null;
            },
            function (error) {

            }
        );
    }
}
