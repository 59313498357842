import angular from "angular";
import $ from 'jquery';
import '../../../lib/jquery-table-exporter/table2CSV.js';
import '../../../lib/jquery-table-exporter/jquery.table2excel.js';

angular
    .module('ui-common')
    .directive("exportTableTo", function () {
        return {
            restrict: 'AE',
            scope: {
                tableIdToExport: '@',
                fileName: "@",
                exclude: "@",
                buttonName: "@"
            },
            template: require('./export-table-to-template.html'),
            link: function ($scope) {

                $scope.exportToExcel = function () {
                    console.log("exportFile");
                    $("#" + $scope.tableIdToExport).table2excel({
                        exclude: $scope.exclude ? $scope.exclude : ".noExport",
                        name: $scope.fileName ? $scope.fileName : "file",
                        fileext: ".xls",
                        filename: $scope.fileName ? $scope.fileName : "file"
                    });
                };

                $scope.exportToCSV = function () {
                    console.log("exportToCSV");
                    $('#' + $scope.tableIdToExport).table2CSV({
                        separator: ',',
                        exclude: $scope.exclude ? $scope.exclude : ".noExport",
                        delivery: 'download',
                        filename: $scope.fileName ? $scope.fileName : "file"
                    });
                };
            }
        };
    });
