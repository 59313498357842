import angular from "angular";

angular
    .module('ui-common')
    .directive('cookielawBlockScript', ['$rootScope', '$document', function($rootScope) {
        return {
            restrict: 'A',
            link: function(scope, element) {
                const deregisterWatch = $rootScope.$watch('isOrsProd', function(newVal) {
                    if (!newVal) {
                        return;
                    }
                    element[0].type = 'text/javascript';
                    element[0].src = 'https://cdn.cookielaw.org/consent/01910a90-1ec6-7442-8acc-4694c878cf56/OtAutoBlock.js';
                    deregisterWatch();
                });
            }
        };
    }]);
