import angular from "angular";

angular
    .module('ui-sentinel.home')
    .config(routes);

routes.$inject = ['$stateProvider'];

function routes($stateProvider) {
    $stateProvider
        .state('home', {
            url: '/home',
            template: require('./home.html'),
            data: {
                authorizationRequired: true
            }
        })
        .state('home-admin', {
            url: '/home-admin',
            template: require('./home-admin.html'),
            data: {
                authorizationRequired: true
            }
        })
    ;
}
