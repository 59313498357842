import angular from "angular";
import moment from "moment/moment";

angular
    .module('ui-sentinel.devices.deviceTracking')
    .factory('DeviceTrackingReportsService', DeviceTrackingReportsService);

DeviceTrackingReportsService.$inject = ['$rootScope', 'localStorageService', 'SentinelUiSession', 'SentryAdminApiService', 'SentryAccountApiService', 'UtilityService'];

function DeviceTrackingReportsService($rootScope, localStorageService, SentinelUiSession, SentryAdminApiService, SentryAccountApiService, UtilityService) {
    const maxPageSize = 5000;
    const maxReportCapacity = 5000;
    let totalCount = 0;
    let clearedFromDate = undefined;
    let clearedToDate = undefined;

    const service = {
        deviceTagId: null,
        lastDeviceTagId: null,
        reports: [],
        lastReport: null,
        selected: null,
        init: init,
        load: load,
        get: getReport,
        defaultToDate: defaultToDate,
        defaultFromDate: defaultFromDate,
        fromDate: moment().subtract(7, 'day').startOf('day'),
        toDate: moment().add(1, 'day').endOf('day'),
        fromDateCache: moment().subtract(7, 'day').startOf('day'),
        toDateCache: moment().add(1, 'day').endOf('day'),
        reportsCache: [],
        isFull: false,
        clear: clear,
        isLoading: false
    };

    service.dateKey = () => service.deviceTagId ? `deviceTrackingFilter_${service.deviceTagId}_` : 'deviceTrackingFilter_null_';

    activate();
    return service;

    function activate() {
        $rootScope.$on('CLIENT_FOCUS_CHANGED', function (event, args) {
            clear();
        });
    }

    function clear() {
        service.selected = null;
        service.reports = [];
        service.deviceTagId = null;
        service.lastDeviceTagId = null;
        service.isFull = false;

        const savedFromDate = UtilityService.getSetting(`${service.dateKey()}.fromDate`);
        const savedToDate = UtilityService.getSetting(`${service.dateKey()}.toDate`);

        clearedFromDate = moment().subtract(7, 'day').startOf('day');
        clearedToDate = moment().add(1, 'day').endOf('day').startOf('minute');

        service.fromDate = savedFromDate ? moment(savedFromDate) : clearedFromDate;
        service.toDate = savedToDate ? moment(savedToDate) : clearedToDate;

        $rootScope.loading = false;
    }

    function defaultToDate() {
        return moment(service.lastReport.timeOfReport).add(1, 'day').endOf('day');
    }

    function defaultFromDate() {
        return defaultToDate().subtract(7, 'day').startOf('day');
    }

    // init() will set the default date range for a device based on when the latest report occurred, if applicable
    // and will load the initial set of reports accordingly
    function init(deviceTagId) {
        clear();

        if (!deviceTagId) {
            return;
        }

        $rootScope.loading = true;

        service.deviceTagId = deviceTagId;

        const promiseLatest = SentinelUiSession.user.isSystemAdmin ?
            SentryAdminApiService.lastSentry500SentinelReportByDevice(service.deviceTagId).$promise :
            SentryAccountApiService.latestSentry500SentinelReportByDevice(service.deviceTagId).$promise;

        // var promiseLatest = SentryAccountApiService.latestSentry500SentinelReportByDevice(service.deviceTagId).$promise;


        promiseLatest.then(function (results2) {
            console.log(results2);
            if (results2)
                service.lastReport = results2;

            const savedFromDate = UtilityService.getSetting(`${service.dateKey()}.fromDate`);
            const savedToDate = UtilityService.getSetting(`${service.dateKey()}.toDate`);

            // set the default dates based on the latest report
            if (service.lastReport) {

                service.toDate = savedToDate && (!clearedToDate || savedToDate.getTime() !== clearedToDate.toDate().getTime())
                    ? moment(savedToDate)
                    : defaultToDate();

                service.fromDate = savedFromDate && (!clearedFromDate || savedFromDate.getTime() !== clearedFromDate.toDate().getTime())
                    ? moment(savedFromDate)
                    : defaultFromDate();

            } else {
                if (savedFromDate) {
                    service.fromDate =  moment(savedFromDate);
                }
                if (savedToDate) {
                    service.toDate = moment(savedToDate);
                }
            }

            load(service.fromDate, service.toDate);

        }, function () {
            $rootScope.loading = false;
        });

    }

    function filterCurrentResult(fromMoment, toMoment) {
        if (service.reports) {
            service.reports = service.reportsCache.filter(function (r) {
                const timeOfReport = moment(r.timeOfReport);
                return !!(fromMoment.isSameOrBefore(timeOfReport) && timeOfReport.isSameOrBefore(toMoment));
            });
            service.isFull = service.reports.length >= maxReportCapacity;
        }
    }

    // load() will load reports for the device provided there is a latest report and valid date range
    function load(fromDate, toDate, refresh) {
        if (!service.deviceTagId || !service.lastReport || !fromDate || !toDate) {
            clear();
            return;
        }

        const oneYearAgo = moment().subtract(364, 'days');
        const fromMoment = moment.isMoment(fromDate) ? fromDate : moment(fromDate);
        const toMoment = moment.isMoment(toDate) ? toDate : moment(toDate);
        const maxToMoment = moment(fromMoment).add(45, 'day');

        if (fromMoment.isBefore(oneYearAgo) || toMoment.isBefore(fromMoment) || toMoment.isAfter(maxToMoment)) {
            return;
        }

        if (!refresh && service.lastDeviceTagId === service.deviceTagId &&
            fromMoment.isSame(service.fromDate) && toMoment.isSame(service.toDate)) {
            //do not reload the same data set again
            return;
        }

        service.lastDeviceTagId = service.deviceTagId;
        service.selected = null;
        service.fromDate = fromMoment;
        service.toDate = toMoment;
        totalCount = 0;

        if (service.fromDateCache && service.fromDateCache.isBefore(fromMoment) &&
            service.toDateCache && toMoment.isBefore(service.toDateCache) && service.reportsCache.length > 0) {
            filterCurrentResult(fromMoment, toMoment);
            return;
        }

        service.fromDateCache = service.fromDate.clone();
        service.toDateCache = service.toDate.clone();

        if (!$rootScope.loading) {
            $rootScope.loading = true;
        }

        service.isLoading = true;

        const promise = SentinelUiSession.user.isSystemAdmin ?
            SentryAdminApiService.listSentry500SentinelReportsByDevice(SentinelUiSession.focus, service.deviceTagId, service.fromDate, service.toDate, 1, maxPageSize).$promise :
            SentryAccountApiService.listSentry500SentinelReportsByDevice(service.deviceTagId, service.fromDate, service.toDate, 1, maxPageSize).$promise;

        promise.then(
            function (results) {
                totalCount = results.length;
                let guid = new Date().getTime();
                _.forEach(results, function (r) {
                    // todo: review this whole block, note the many properties being set to themselves
                    // maybe they are supposed to be other properties in play
                    r.guid = guid++;
                    r.severity = "ok";
                    r.deviceTagId = r.imei;
                    if (!r.deviceName)
                        r.deviceName = r.imei;
                    r.messageTimeStamp = r.timeOfReport;
                    r.temperatureC = r.temperatureValueC;
                    r.temperatureF = r.temperatureValueF;
                    r.dewPointC = r.dewPointC;
                    r.dewPointF = r.dewPointF;
                    r.extSwitch = r.extSwitch;
                    r.temperature1C = r.temperatureProbe1C;
                    r.temperature1F = r.temperatureProbe1F;
                    r.temperature2C = r.temperatureProbe2C;
                    r.temperature2F = r.temperatureProbe2F;
                    if (r.humidity)
                        r.humidity = r.humidity;
                    r.isButtonPushed = r.reportFlags_ButtonPushed;
                    if (r.pressureValue)
                        r.pressure = r.pressureValue;
                    r.batteryPercent = r.battery;
                    r.light = r.lightValue;
                    r.reportGuid = r.sentryStatusId;
                    r.reportInterval = r.nextReportInterval;
                    r.isShockExceeded = (r.shockX != null && r.shockY != null && r.shockZ != null);
                    if (r.isShockExceeded) {
                        r.shockMagnitude = Math.sqrt(Math.pow(r.shockX, 2) + Math.pow(r.shockY, 2) + Math.pow(r.shockZ, 2)).toFixed(1);
                    }
                    r.isTiltExceeded = (r.tiltX != null && r.tiltY != null && r.tiltZ != null);
                    if (r.isTiltExceeded) {
                        r.tiltMagnitude = Math.sqrt(Math.pow(r.tiltX, 2) + Math.pow(r.tiltY, 2) + Math.pow(r.tiltZ, 2)).toFixed(1);
                    }
                });
                service.reports = results;
                service.reportsCache = results.slice();
                service.isFull = service.reports.length === maxReportCapacity;
            },
            function (error) {
                //todo: handle error
            }
        ).finally(function () {
            $rootScope.loading = false;
            service.isLoading = false;
        });
    }

    function getReport(guid) {
        const target = `${guid}`
        return service.reports.find(report => `${report.guid}` === target);
    }
}
