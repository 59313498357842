import angular from "angular";

angular
    .module('ui-sentinel.watchlist')
    .config(routes);

routes.$inject = ['$stateProvider', 'SENTINEL_API_HOST_CONSTANTS'];

function routes($stateProvider, USER_ROLES) {
    $stateProvider
        .state('watchlist', {
            abstract: true,
            url: '/watchlist',
            template: '<ui-view/>',
            data: {
                authorizationRequired: true,
                authorizedRoles: [
                    USER_ROLES.systemAdmin,
                    USER_ROLES.accountAdmin,
                    USER_ROLES.accountEditor
                ],
                pageTitle: 'Watchlists',
                subTitle: null,
                parentState: null
            }
        })
        .state('watchlist.tracking', {
            url: '/tracking',
            template: require('./watchlist-tracking.html'),
            data: {
                pageTitle: 'Watchlist Tracking',
                parentState: 'watchlist.tracking'
            },
            params: {
                reload: false
            }
        })
        .state('watchlist.reports', {
            url: '/reports',
            template: require('./watchlist-tracking-list.html'),
            data: {
                pageTitle: 'Track Watchlist',
                subTitle: null,
                parentState: null
            }
        })
        .state('watchlist.list', {
            url: '/:sentinelId',
            template: require('./watchlist-list.html'),
            params: {
                referrer: 'watchlist',
                referrerParams: null,
                clearMessage: true
            }
        })
        /* commenting this out because the template does not exist
        .state('watchlist.admin', {
             url: '/:watchlistId/admin',
             template: require('./watchlist-admin.html'),
             params: {
                 referrer: 'watchlist',
                 referrerParams: null,
                 clearMessage: true
             }
         })*/
        .state('watchlist.data', {
            url: '/:watchlistId/data',
            template: require('./watchlist-data.html'),
            params: {
                referrer: 'watchlist',
                referrerParams: null,
                watchlist: null
            }
        })
        .state('sentinel-watchlist-watchlists', {
            abstract: true,
            url: '/sentinelwatchlistswatchlists',
            template: '<ui-view/>',
            data: {
                authorizationRequired: true,
                pageTitle: 'Watchlists for Sentinel',
                subTitle: null,
                parentState: null
            }
        })
        .state('sentinel-watchlist-watchlists.tracking', {
            url: '/:sentinelId',
            template: require('./sentinel-watchlist-watchlists-tracking.html')
        });
}
