import angular from "angular";

angular
    .module('ui-sentinel.assets')
    .config(routes);

routes.$inject = ['$stateProvider', 'SENTINEL_API_HOST_CONSTANTS'];

function routes($stateProvider, USER_ROLES) {
    $stateProvider
        .state('assets', {
            abstract: true,
            url: '/assets',
            template: '<ui-view/>',
            data: {
                authorizationRequired: true,
                authorizedRoles: [
                    USER_ROLES.systemAdmin,
                    USER_ROLES.supportAdmin,
                    USER_ROLES.supportObserver,
                    USER_ROLES.accountAdmin,
                    USER_ROLES.accountEditor
                ],
                pageTitle: 'Manage assets',
                subTitle: null,
                parentState: null
            }
        })
        .state('assets.list', {
            url: '/list',
            template: require('./assets-list.html')
        })
        .state('asset', {
            abstract: true,
            url: '/asset',
            template: '<ui-view/>',
            data: {
                authorizationRequired: true,
                authorizedRoles: [
                    USER_ROLES.systemAdmin,
                    USER_ROLES.supportAdmin,
                    USER_ROLES.supportObserver,
                    USER_ROLES.accountAdmin,
                    USER_ROLES.accountEditor
                ],
                pageTitle: 'Manage assets',
                subTitle: null,
                parentState: 'assets.list'
            }
        })
        .state('asset.new', {
            url: '/new',
            template: require('./asset-admin.html'),
            params: {
                referrer: 'assets.list',
                referrerParams: null,
                clearMessage: true
            },
            data: {
                subTitle: 'Create a new asset'
            }
        })
        .state('asset.admin', {
            url: '/:sentinelId/admin',
            template: require('./asset-admin.html'),
            params: {
                referrer: 'assets.list',
                referrerParams: null,
                clearMessage: true
            }
        })
        .state('asset.details', {
            url: '/:zoneId/:sentinelId/details',
            template: require('./asset-view-details.html'),
            params: {
                referrer: 'assets.list',
                referrerParams: null,
                clearMessage: true
            },
            data: {
                subTitle: 'Details'
            }
        });
}
