import angular from "angular";

angular
    .module('ui-common')
    .directive('apiDataPager', function () {
        return {
            require: '?ngModel',
            restrict: 'E',
            replace: true,

            template: require('./data-pager-template.html'),
            scope: {
                ngModel: '=',
                translations: '=',
                service: '=',
                pageLinksToDisplay: '='
            },
            link: function ($scope, elem, attrs) {
                const visiblePages = $scope.pageLinksToDisplay ?? 10;
                let minVisibleLinkIndex;
                let maxVisibleLinkIndex;

                const update = () => {
                    const currentIndex = $scope.service.currentPage - 1;
                    const maxIndex = $scope.service.maxPage - 1;
                    minVisibleLinkIndex = Math.max(currentIndex - visiblePages / 2, 0);
                    maxVisibleLinkIndex = Math.min((currentIndex + visiblePages / 2) - 1, maxIndex);
                    if (maxVisibleLinkIndex - minVisibleLinkIndex < visiblePages - 1) {
                        if (minVisibleLinkIndex === 0) {
                            maxVisibleLinkIndex = Math.min(visiblePages - 1, maxIndex);
                        } else if (maxVisibleLinkIndex === maxIndex) {
                            minVisibleLinkIndex = Math.max(0, maxVisibleLinkIndex - visiblePages);
                        }
                    }
                }
                $scope.$watch('service.maxPage', () => {
                    update();
                });
                $scope.$watch('service.currentPage', () => {
                    update();
                });
                $scope.showPageLink = (i) => {
                    return i >= minVisibleLinkIndex && i <= maxVisibleLinkIndex;
                };
                $scope.firstPageLinkShown = () => {
                    return minVisibleLinkIndex === 0;
                };
                $scope.lastPageLinkShown = () => {
                    return maxVisibleLinkIndex === $scope.service.maxPage - 1;
                };
            }
        }
    });

