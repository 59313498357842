import angular from "angular";

const orsBrand = 'ors';
const markenBrand = 'marken';
const prodOrsDomain = 'gps.organ-recovery.com';

const brands = [
    {
        name: 'onasset',
        domains: [
            {
                name: '*',
                tracking: '*'
            }
        ],
        theme: '/themes/default/main.css'
    },
    {
        name: orsBrand,
        domains: [
            {
                name: 'insight1.ors.localhost.com',
                tracking: 'insight2.ors.localhost.com:3000'
            },
            {
                name: 'gps.qa.organ-recovery.com',
                tracking: 'tracking.qa.organ-recovery.com'
            },
            {
                name: prodOrsDomain,
                tracking: 'tracking.organ-recovery.com'
            }
        ],
        theme: '/themes/ors/main.css'
    },
    {
        name: markenBrand,
        domains: [
            {
                name: 'insight1.marken.localhost.com',
                tracking: 'insight2.marken.localhost.com'
            },
            {
                name: 'oainsight-onasset-marken.azurewebsites.net',
                tracking: 'oainsight-angular-marken.azurewebsites.net'
            }
        ],
        theme: '/themes/marken/main.css'
    }
];

angular
    .module('sentinel.gateway.web')
    .run(runBlock);

runBlock.$inject = ['$rootScope', 'DefaultTrackingUrl'];

function runBlock($rootScope, DefaultTrackingUrl) {
    const domain = window.location.hostname;
    $rootScope.currentBrand = brands.find((b) => b.domains.find((d) => {
        const match = d.name === domain;
        if (match) {
            $rootScope.brandTrackingDomain = d.tracking;
        }
        return match;
    })) || brands[0];

    $rootScope.isOrs = $rootScope.currentBrand.name === orsBrand;
    $rootScope.isOrsProd = window.location.hostname === prodOrsDomain;
    $rootScope.isMarken = $rootScope.currentBrand.name === markenBrand;
    $rootScope.trackingUrl = $rootScope.brandTrackingDomain
        ? `${window.location.protocol}//${$rootScope.brandTrackingDomain}/track/`
        : DefaultTrackingUrl;

    const linkElement = document.createElement('link');
    linkElement.rel = 'stylesheet';
    linkElement.href = $rootScope.currentBrand.theme;

    document.head.appendChild(linkElement);
}
