import angular from "angular";
import moment from "moment/moment";

angular
    .module('ui-sentinel.shipments.shipmentTracking')
    .factory('ShipmentTrackingReportsService', ShipmentTrackingReportsService);

ShipmentTrackingReportsService.$inject = ['$rootScope', 'localStorageService', 'ShipmentsService', 'ShipmentsReportService', 'UtilityService'];

function ShipmentTrackingReportsService($rootScope, localStorageService, ShipmentsService, ShipmentsReportService, UtilityService) {
    const maxPageSize = 500;
    const maxReportCapacity = 500;
    let totalCount = 0;
    let clearedFromDate = undefined;
    let clearedToDate = undefined;

    const service = {
        shipmentId: null,
        lastShipmentId: null,
        shipmentInfo: null,
        reports: [],
        fullReports: [],
        lastReport: null,
        selected: null,
        init: init,
        load: load,
        get: getReport,
        fromDate: moment().subtract(7, 'day').startOf('day'),
        toDate: moment().add(1, 'day').endOf('day'),
        isFull: false,
        clear: clear
    };

    service.dateKey = () => service.shipmentId ? `shipmentTrackingFilter_${service.shipmentId}_` : 'shipmentTrackingFilter_null_';

    activate();
    return service;

    function activate() {
        $rootScope.$on('CLIENT_FOCUS_CHANGED', function (event, args) {
            clear();
        });
    }

    function clear() {
        service.selected = null;
        service.reports = [];
        service.lastReport = null;
        service.shipmentId = null;
        service.lastShipmentId = null;
        service.shipmentInfo = null;
        service.isFull = false;

        const savedFromDate = UtilityService.getSetting(`${service.dateKey()}.fromDate`);
        const savedToDate = UtilityService.getSetting(`${service.dateKey()}.toDate`);

        clearedFromDate = moment().subtract(7, 'day').startOf('day');
        clearedToDate = moment().add(1, 'day').endOf('day').startOf('minute');

        service.fromDate = savedFromDate ? moment(savedFromDate) : clearedFromDate;
        service.toDate = savedToDate ? moment(savedToDate) : clearedToDate;
    }

    function init(shipmentId) {
        clear();

        if (!shipmentId) {
            return;
        }

        service.shipmentId = shipmentId;

        $rootScope.loading = true;

        const shipmentPromise = ShipmentsService.getShipment(service.shipmentId).$promise;
        shipmentPromise.then(
            function (shipment) {
                service.shipmentInfo = shipment.shipmentInfo;

                const savedFromDate = UtilityService.getSetting(`${service.dateKey()}.fromDate`);
                const savedToDate = UtilityService.getSetting(`${service.dateKey()}.toDate`);

                service.toDate = moment(service.shipmentInfo.trackingEndDate);

                if (service.toDate.isAfter(new Date())) {
                    service.fromDate = savedFromDate ? moment(savedFromDate) : moment().add(1, 'day').endOf('day');
                    service.toDate = savedToDate ? moment(savedToDate) :moment().add(1, 'day').endOf('day');
                } else {
                    service.toDate = savedToDate ? moment(savedToDate) :service.toDate.add(1, 'day').endOf('day');
                    service.fromDate = savedFromDate ? moment(savedFromDate) : moment(service.shipmentInfo.trackingEndDate).add(1, 'day').endOf('day');
                }

                if (!savedFromDate) {
                    service.fromDate.subtract(14, 'day').startOf('day');
                }

                const lastReportPromise = ShipmentsReportService.getLatestShipmentReport(service.shipmentId).$promise;
                lastReportPromise.then(
                    function (results) {
                        $rootScope.loading = false;
                        service.lastReport = results;
                        if (service.lastReport) {
                            const savedFromDate = UtilityService.getSetting(`${service.dateKey()}.fromDate`);
                            const savedToDate = UtilityService.getSetting(`${service.dateKey()}.toDate`);

                            service.toDate = savedToDate && (!clearedToDate || savedToDate.getTime() !== clearedToDate.toDate().getTime())
                                ? moment(savedToDate)
                                : moment(service.lastReport.messageTimeStamp).add(1, 'day').endOf('day');

                            service.fromDate = savedFromDate && (!clearedFromDate || savedFromDate.getTime() !== clearedFromDate.toDate().getTime())
                                ? moment(savedFromDate)
                                : moment(service.shipmentInfo.trackingStartDate);

                            console.log(service.toDate.diff(service.fromDate));
                            if (!savedFromDate && service.toDate.diff(service.fromDate) > 14) {
                                service.fromDate = moment(service.lastReport.messageTimeStamp).subtract(14, 'day').startOf('day');
                            }
                        }

                        console.log("fromDate", service.fromDate);
                        console.log("toDate", service.toDate);

                        load(service.fromDate, service.toDate, true);
                    },
                    function (error) {
                        //todo: handle error
                        console.log(error);
                        $rootScope.loading = false;
                    }
                );
            },
            function (error) {
                //todo: handle error
                $rootScope.loading = false;

                console.log(error);
            }
        );
    }

    function load(fromDate, toDate, refresh) {
        if (!service.shipmentId || !service.lastReport || !fromDate || !toDate) {
            clear();
            return;
        }

        const oneYearAgo = moment().subtract(364, 'days');
        const fromMoment = moment.isMoment(fromDate) ? fromDate : moment(fromDate);
        const toMoment = moment.isMoment(toDate) ? toDate : moment(toDate);
        const maxToMoment = moment(fromMoment).add(45, 'day');

        if (fromMoment.isBefore(oneYearAgo) || toMoment.isBefore(fromMoment) || toMoment.isAfter(maxToMoment)) {
            return;
        }

        if (!refresh && service.lastShipmentId === service.shipmentId &&
            fromMoment.isSame(service.fromDate) && toMoment.isSame(service.toDate)) {
            //do not reload the same data set again
            return;
        }

        service.lastShipmentId = service.shipmentId;
        service.selected = null;
        service.fromDate = fromMoment;
        service.toDate = toMoment;
        totalCount = 0;

        if (refresh) {
            $rootScope.loading = true;
            const promise = ShipmentsReportService.getShipmentReports(service.shipmentId, service.fromDate, service.toDate, 1, maxPageSize).$promise;
            promise.then(
                function (results) {
                    results = _.orderBy(results, ['messageTimeStamp'], ['desc']);

                    const newList = [];
                    for (var i = 0; i < results.length; i++) {
                        const o = results[i];
                        const exist = newList.find(function (element) {
                            return element.reportGuid == o.reportGuid;
                        });
                        if (!exist) {
                            newList.push(o);
                        } else {
                            if (exist.severity == "ok") {
                                var index = newList.find(function (element) {
                                    return element.reportGuid == o.reportGuid;
                                });
                                newList[index] = o;
                            }
                        }
                    }
                    for (var i = newList.length - 1; i >= 0; i--) {
                        var r = newList[i];
                        const activatedAlarms = r.activatedAlarms.map(function (item) {
                            return item.alarmName;
                        });
                        r.activatedAlarms = r.activatedAlarms.filter(function (item, index) {
                            return activatedAlarms.indexOf(item.alarmName) >= index;
                        });
                        r.isShockExceeded = (r.shockX != null && r.shockY != null && r.shockZ != null);
                        if (r.isShockExceeded) {
                            r.shockMagnitude = Math.sqrt(Math.pow(r.shockX, 2) + Math.pow(r.shockY, 2) + Math.pow(r.shockZ, 2)).toFixed(1);
                        }
                        r.isTiltExceeded = (r.tiltX || r.tiltY || r.tiltZ);
                        if (r.isTiltExceeded) {
                            r.tiltMagnitude = Math.sqrt(Math.pow(r.tiltX, 2) + Math.pow(r.tiltY, 2) + Math.pow(r.tiltZ, 2)).toFixed(1);
                        }
                    }
                    service.fullReports = newList;
                    let filterList = service.fullReports.filter((r) => {
                        let time = moment(r.messageTimeStamp);
                        return time.isBetween(service.fromDate, service.toDate);
                    });
                    totalCount = filterList.length;
                    service.reports = filterList;
                    service.isFull = service.reports.length === maxReportCapacity;
                },
                function (error) {
                    //todo: handle error
                }
            ).finally(function () {
                $rootScope.loading = false;
            });
        } else {
            // should only attempt this if date range is smaller and contained in the previous range
            // this doesn't even work anyway, so the calling function now sets refresh to true
            // todo: fix this feature or remove it
            let filterList = service.fullReports.filter((r) => {
                let time = moment(r.messageTimeStamp);
                return time.isBetween(service.fromDate, service.toDate);
            });
            totalCount = filterList.length;
            service.reports = filterList;
            service.isFull = service.reports.length === maxReportCapacity;
        }
    }

    function getReport(reportGuid) {
        let result = null;
        _.forEach(service.reports, function (report) {
            if (report.reportGuid === reportGuid) {
                result = report;
                return false;
            }
        });
        return result;
    }
}
