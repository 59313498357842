import angular from "angular";

angular
    .module('ui-sentinel.locations')
    .config(routes);

routes.$inject = ['$stateProvider', 'SENTINEL_API_HOST_CONSTANTS'];

function routes($stateProvider, USER_ROLES) {
    $stateProvider
        .state('locations', {
            abstract: true,
            url: '/locations',
            template: '<ui-view/>',
            data: {
                authorizationRequired: true,
                authorizedRoles: [
                    USER_ROLES.systemAdmin,
                    USER_ROLES.supportAdmin,
                    USER_ROLES.supportObserver,
                    USER_ROLES.accountAdmin,
                    USER_ROLES.accountEditor
                ],
                pageTitle: 'Manage Locations',
                subTitle: null,
                parentState: null
            }
        })
        .state('locations.list', {
            url: '/list',
            template: require('./locations-list.html')
        })
        .state('locations.tracking', {
            url: '/tracking',
            template: require('./locations-tracking.html'),
            data: {
                pageTitle: 'Locations Tracking'
            }
        })
        .state('location', {
            abstract: true,
            url: '/locations',
            template: '<ui-view/>',
            data: {
                authorizationRequired: true,
                authorizedRoles: [
                    USER_ROLES.systemAdmin,
                    USER_ROLES.supportAdmin,
                    USER_ROLES.supportObserver,
                    USER_ROLES.accountAdmin,
                    USER_ROLES.accountEditor
                ],
                pageTitle: 'Manage Locations',
                subTitle: null,
                parentState: 'locations.list'
            }
        })
        .state('location.new', {
            url: '/new',
            template: require('./location-admin.html'),
            params: {
                referrer: 'locations.list',
                referrerParams: null,
                clearMessage: true
            },
            data: {
                subTitle: 'Create a new location'
            }
        })
        .state('location.admin', {
            url: '/:locationId/admin',
            template: require('./location-admin.html'),
            params: {
                referrer: 'locations.list',
                referrerParams: null,
                clearMessage: true
            }
        })
        .state('location.details', {
            url: '/:locationId/details',
            template: require('./location-details.html'),
            params: {
                referrer: 'locations.list',
                referrerParams: null,
                clearMessage: true
            },
            data: {
                pageTitle: 'Manage Locations-Details',
            }
        });
}
