import angular from "angular";

angular
    .module('sentinel.gateway.web')
    .config(config);

config.$inject = ['$stateProvider', '$urlRouterProvider', '$locationProvider', 'localStorageServiceProvider'];

function config($stateProvider, $urlRouterProvider, $locationProvider, localStorageServiceProvider) {
    localStorageServiceProvider
        .setPrefix('sentinel.api.web')
        .setStorageType('sessionStorage')
        .setNotify(true, true);

    $urlRouterProvider.otherwise('/login'); // /login
}
