import angular from "angular";

angular
    .module('ui-sentinel.watchlist')
    .factory('WatchlistTrackingReportsService', WatchlistTrackingReportsService);

WatchlistTrackingReportsService.$inject = ['$rootScope', 'SentinelUiSession', 'localStorageService', 'WatchlistsService', 'DatetimeValidatorService'];

function WatchlistTrackingReportsService($rootScope, SentinelUiSession, localStorageService, WatchlistsService, DatetimeValidatorService) {
    var maxPageSize = 5000;
    var maxReportCapacity = 150000;
    var totalCount = 0;
    var localStorageKey = 'watchlistTrackingReportsService';

    var service = {
        lastClientGuid: null,
        reports: [],
        selected: null,
        load: load,
        get: get,
        fromDate: moment().subtract(7, 'day').startOf('day'),
        toDate: moment().add(1, 'day').endOf('day'),
        watchlist: null,
        sentinel: null,
        isFull: false,
        clear: clear
    };
    activate();
    return service;

    ///////////////////////////////////////////////////

    function activate() {
        //in case this is a page reload event, check the local storage for 'cached' dates and use those
        var cachedDateRange = localStorageService.get(localStorageKey);
        if (cachedDateRange) {
            service.fromDate = moment(cachedDateRange.fromDate);
            service.toDate = moment(cachedDateRange.toDate);
        }

        $rootScope.$on('CLIENT_FOCUS_CHANGED', function (event, args) {
            clear();
        });
    }

    function clear() {
        service.lastClientGuid = null;
        service.selected = null;
        service.reports = [];
        service.isFull = false;
        service.fromDate = moment().subtract(7, 'day').startOf('day');
        service.toDate = moment().add(1, 'day').endOf('day');
    }

    function BatteryPercentageFilter(voltage) {
        var SOC_MIN = 0;
        var SOC_MAX = 100;
        var V_MIN = 21;
        var V_MAX = 35;

        voltage = voltage * 10;

        if (voltage == 255) {
            return null;
        }

        if (voltage < V_MIN) {
            return SOC_MIN;
        }

        var percent = SOC_MIN + (voltage - V_MIN) * (SOC_MAX - SOC_MIN) / (V_MAX - V_MIN);

        if (percent > SOC_MAX) {
            return SOC_MAX;
        } else {
            return Math.round(percent * 100) / 100;
        }
    }

    function floorFigure(figure, decimals) {
        if (!decimals) decimals = 2;
        var d = Math.pow(10, decimals);
        return (parseInt(figure * d) / d).toFixed(decimals);
    }

    function load(watchlist, fromDate, toDate, refresh) {
        if (!watchlist || !fromDate || !toDate) {
            clear();
            return;
        }

        var oneYearAgo = moment().subtract(1, 'year');
        var fromMoment = moment.isMoment(fromDate) ? fromDate : moment(fromDate);
        var toMoment = moment.isMoment(toDate) ? toDate : moment(toDate);
        var maxToMoment = moment(fromMoment).add(240, 'day');

        if (fromMoment.isBefore(oneYearAgo) || toMoment.isBefore(fromMoment) || toMoment.isAfter(maxToMoment)) {
            return;
        }

        if (!refresh && service.lastClientGuid === SentinelUiSession.focus.id &&
            fromMoment.isSame(service.fromDate) && toMoment.isSame(service.toDate)) {
            //do not reload the same data set again
            return;
        }

        $rootScope.loading = true;

        service.lastid = SentinelUiSession.focus.id;
        service.selected = null;
        service.fromDate = fromMoment;
        service.toDate = toMoment;
        service.watchlist = watchlist;
        totalCount = 0;

        var cachedDateRange = {
            fromDate: service.fromDate.toISOString(),
            toDate: service.toDate.toISOString()
        };
        localStorageService.set(localStorageKey, cachedDateRange);
        LoadLogData(1, 0, watchlist);
    }

    function LoadLogData(pagenum, totalCount, watchlist) {

        var hasMoreData = true;

        var promise = WatchlistsService.getWatchlistLogData(watchlist.id, pagenum, maxPageSize, service.fromDate, service.toDate).$promise;

        promise.then(
            function (results) {
                _.forEach(results, function (item) {
                    item.type = "Sentinel";
                    item.severity = "ok";
                    item.locationMethod = "gps";
                    item.messageTimeStamp = item.timeOfReport;
                    item.lastReport = item.timeOfReport;
                    item.batteryPercent = BatteryPercentageFilter(item.batteryVoltage);
                });

                if (pagenum === 1) {
                    totalCount = results.length;
                    service.reports = [];
                } else {
                    totalCount = totalCount + results.length;
                }

                service.reports = service.reports.concat(results);
                service.isFull = totalCount >= maxReportCapacity;

                hasMoreData = ((results.length == 5000) && (!service.isFull));
                pagenum++;
            },
            function (error) {
                hasMoreData = false;
            }
        )
            .finally(function () {
                if (hasMoreData)
                    LoadLogData(pagenum, totalCount, watchlist);
                else {
                    for (var x = service.reports.length - 2; x >= 0; x--) {
                        service.reports[x].lastReport = service.reports[x + 1].timeOfReport;
                        var dif = moment.duration(moment(service.reports[x].timeOfReport).diff(moment(service.reports[x].lastReport), 'milliseconds'));
                        service.reports[x].timeDiff = convertMilliSecondsIntoLegibleString(dif.asMilliseconds());
                    }

                    $rootScope.loading = false;
                }
            });
    }

    function convertMilliSecondsIntoLegibleString(milliSecondsIn) {

        var secsIn = milliSecondsIn / 1000;
        var milliSecs = milliSecondsIn % 1000;

        var hours = secsIn / 3600,
            remainder = secsIn % 3600,
            minutes = remainder / 60,
            seconds = remainder % 60;

        return (String(hours.toFixed(0)).padStart(2, '0') + ":" + String(minutes.toFixed(0)).padStart(2, '0') + ":" + String(seconds.toFixed(0)).padStart(2, '0'));
    }

    function get(guid) {
        var result = null;
        _.forEach(service.reports, function (report) {
            if (report.sentinelLogDataId == guid) {
                result = report;
                return false;
            }
        });
        return result;
    }
}
