import angular from "angular";

angular
    .module('api-sentinel')
    .factory('ShipmentListService', ShipmentListService);

ShipmentListService.$inject = ['$resource', 'SENTINEL_API_HOST_CONSTANTS'];

function ShipmentListService($resource, HOST) {
    var api = $resource(HOST.URL + '/rest/1/shipmentlist', {}, {
        getShipmentList: {
            method: 'GET',
            url: HOST.URL + '/rest/1/shipmentlist/GetAllShipmentListItems',
            isArray: true
        },
        GetAllShipmentListItemsByDateRange: {
            method: 'GET',
            url: HOST.URL + '/rest/1/shipmentlist/GetAllShipmentListItemsByDateRange',
            isArray: true
        },
        GetAllShipmentListItemsByDateRangeCount: {
            method: 'GET',
            url: HOST.URL + '/rest/1/shipmentlist/GetAllShipmentListItemsByDateRange/count'
        },
        GetAllShipmentListItemsByDateForClientRange: {
            method: 'GET',
            url: HOST.URL + '/rest/1/shipmentlist/GetAllShipmentListItemsForClientByRange',
            isArray: true
        },
        GetAllShipmentListItemsByDateForClientRangeCount: {
            method: 'GET',
            url: HOST.URL + '/rest/1/shipmentlist/GetAllShipmentListItemsForClientByRange/count'
        },

        GetAllShipmentListItemsByCreateDateRange: {
            method: 'GET',
            url: HOST.URL + '/rest/1/shipmentlist/GetAllShipmentListItemsByCreateDateRange',
            isArray: true
        },
        GetAllShipmentListItemsByCreateDateRangeCount: {
            method: 'GET',
            url: HOST.URL + '/rest/1/shipmentlist/GetAllShipmentListItemsByCreateDateRange/count'
        },
        GetAllShipmentListItemsByCreateDateForClientRange: {
            method: 'GET',
            url: HOST.URL + '/rest/1/shipmentlist/GetAllShipmentListItemsByCreateDateForClientRange',
            isArray: true
        },
        GetAllShipmentListItemsByCreateDateForClientRangeCount: {
            method: 'GET',
            url: HOST.URL + '/rest/1/shipmentlist/GetAllShipmentListItemsByCreateDateForClientByRange/count'
        },
        getActiveShipmentList: { method: 'GET', url: HOST.URL + '/rest/1/shipmentlist/active', isArray: true },
        getPendingShipmentList: { method: 'GET', url: HOST.URL + '/rest/1/shipmentlist/pending', isArray: true },
        getInTransitShipmentList: { method: 'GET', url: HOST.URL + '/rest/1/shipmentlist/in-transit', isArray: true },
        getCompletedShipmentList: { method: 'GET', url: HOST.URL + '/rest/1/shipmentlist/complete', isArray: true },
        getCancelledShipmentList: { method: 'GET', url: HOST.URL + '/rest/1/shipmentlist/cancelled', isArray: true },
        getExpiredShipmentList: { method: 'GET', url: HOST.URL + '/rest/1/shipmentlist/expired', isArray: true },
        getOverdueShipmentList: { method: 'GET', url: HOST.URL + '/rest/1/shipmentlist/overdue', isArray: true },
        getShipmentListItem: { method: 'GET', url: HOST.URL + '/rest/1/shipmentlist/:shipmentId' }
    });

    //decided to return a service definition instead of the resource so that UI developer
    //can be abstracted from details of $resource and request logic can be encapsulated if necessary
    var service = {
        getShipmentList: getShipmentList,
        GetAllShipmentListItemsByDateRange: getAllShipmentListItemsByDateRange,
        GetAllShipmentListItemsByDateRangeCount: GetAllShipmentListItemsByDateRangeCount,
        GetAllShipmentListItemsByDateForClientRange: GetAllShipmentListItemsByDateForClientRange,
        GetAllShipmentListItemsByDateForClientRangeCount: GetAllShipmentListItemsByDateForClientRangeCount,
        GetAllShipmentListItemsByCreateDateRange: getAllShipmentListItemsByCreateDateRange,
        GetAllShipmentListItemsByCreateDateRangeCount: getAllShipmentListItemsByCreateDateRangeCount,
        GetAllShipmentListItemsByCreateDateForClientRange: getAllShipmentListItemsByCreateDateForClientRange,
        GetAllShipmentListItemsByCreateDateForClientRangeCount: getAllShipmentListItemsByCreateDateForClientRangeCount,
        getActiveShipmentList: getActiveShipmentList,
        getPendingShipmentList: getPendingShipmentList,
        getInTransitShipmentList: getInTransitShipmentList,
        getCompletedShipmentList: getCompletedShipmentList,
        getCancelledShipmentList: getCancelledShipmentList,
        getExpiredShipmentList: getExpiredShipmentList,
        getOverdueShipmentList: getOverdueShipmentList,
        getShipmentListItem: getShipmentListItem,
        resource: api
    };

    return service;

    function getActiveShipmentList(client, fromDate, toDate) {
        var fromDateIso = (fromDate instanceof moment) ? fromDate.toISOString() : moment(fromDate).toISOString();
        var toDateIso = (toDate instanceof moment) ? toDate.toISOString() : moment(toDate).toISOString();

        return api.getActiveShipmentList({ clientGuid: client.clientGuid, from: fromDateIso, to: toDateIso });
    }

    function getPendingShipmentList(client, fromDate, toDate) {
        var fromDateIso = (fromDate instanceof moment) ? fromDate.toISOString() : moment(fromDate).toISOString();
        var toDateIso = (toDate instanceof moment) ? toDate.toISOString() : moment(toDate).toISOString();

        return api.getPendingShipmentList({ clientGuid: client.clientGuid, from: fromDateIso, to: toDateIso });
    }

    function getInTransitShipmentList(client, fromDate, toDate) {
        var fromDateIso = (fromDate instanceof moment) ? fromDate.toISOString() : moment(fromDate).toISOString();
        var toDateIso = (toDate instanceof moment) ? toDate.toISOString() : moment(toDate).toISOString();

        return api.getInTransitShipmentList({ clientGuid: client.clientGuid, from: fromDateIso, to: toDateIso });
    }

    function getCompletedShipmentList(client, fromDate, toDate) {
        var fromDateIso = (fromDate instanceof moment) ? fromDate.toISOString() : moment(fromDate).toISOString();
        var toDateIso = (toDate instanceof moment) ? toDate.toISOString() : moment(toDate).toISOString();

        return api.getCompletedShipmentList({ clientGuid: client.clientGuid, from: fromDateIso, to: toDateIso });
    }

    function getCancelledShipmentList(client, fromDate, toDate) {
        var fromDateIso = (fromDate instanceof moment) ? fromDate.toISOString() : moment(fromDate).toISOString();
        var toDateIso = (toDate instanceof moment) ? toDate.toISOString() : moment(toDate).toISOString();

        return api.getCancelledShipmentList({ clientGuid: client.clientGuid, from: fromDateIso, to: toDateIso });
    }

    function getExpiredShipmentList(client, fromDate, toDate) {
        var fromDateIso = (fromDate instanceof moment) ? fromDate.toISOString() : moment(fromDate).toISOString();
        var toDateIso = (toDate instanceof moment) ? toDate.toISOString() : moment(toDate).toISOString();

        return api.getExpiredShipmentList({ clientGuid: client.clientGuid, from: fromDateIso, to: toDateIso });
    }

    function getOverdueShipmentList(client, fromDate, toDate) {
        var fromDateIso = (fromDate instanceof moment) ? fromDate.toISOString() : moment(fromDate).toISOString();
        var toDateIso = (toDate instanceof moment) ? toDate.toISOString() : moment(toDate).toISOString();

        return api.getOverdueShipmentList({ clientGuid: client.clientGuid, from: fromDateIso, to: toDateIso });
    }

    function getShipmentListItem(shipmentId) {

        return api.getShipmentListItem({ shipmentId: shipmentId });
    }

    function getShipmentList(client) {
        return api.getShipmentList({ clientGuid: client.clientGuid });
    }

    function getAllShipmentListItemsByDateRange(client, fromDate, toDate, page, itemsPerPage) {
        var fromDateIso = (fromDate instanceof moment) ? fromDate.toISOString() : moment(fromDate).toISOString();
        var toDateIso = (toDate instanceof moment) ? toDate.toISOString() : moment(toDate).toISOString();

        return api.GetAllShipmentListItemsByDateRange({
            clientGuid: client.clientGuid,
            from: fromDateIso,
            to: null,
            page: page,
            itemsPerPage: itemsPerPage
        });
    }

    function GetAllShipmentListItemsByDateForClientRange(client, fromDate, toDate, page, itemsPerPage) {
        var fromDateIso = (fromDate instanceof moment) ? fromDate.toISOString() : moment(fromDate).toISOString();
        var toDateIso = (toDate instanceof moment) ? toDate.toISOString() : moment(toDate).toISOString();

        return api.GetAllShipmentListItemsByDateForClientRange({
            clientGuid: client.id,
            from: fromDateIso,
            to: null,
            page: page,
            itemsPerPage: itemsPerPage
        });
    }

    function GetAllShipmentListItemsByDateRangeCount(client, fromDate, toDate, itemsPerPage) {
        var fromDateIso = (fromDate instanceof moment) ? fromDate.toISOString() : moment(fromDate).toISOString();
        var toDateIso = (toDate instanceof moment) ? toDate.toISOString() : moment(toDate).toISOString();

        return api.GetAllShipmentListItemsByDateRangeCount({
            clientGuid: client.clientGuid,
            from: fromDateIso,
            to: null,
            itemsPerPage: itemsPerPage
        });
    }

    function GetAllShipmentListItemsByDateForClientRangeCount(client, fromDate, toDate, itemsPerPage) {
        var fromDateIso = (fromDate instanceof moment) ? fromDate.toISOString() : moment(fromDate).toISOString();
        var toDateIso = (toDate instanceof moment) ? toDate.toISOString() : moment(toDate).toISOString();

        return api.GetAllShipmentListItemsByDateForClientRangeCount({
            clientGuid: client.id,
            from: fromDateIso,
            to: null,
            itemsPerPage: itemsPerPage
        });
    }

    function getAllShipmentListItemsByCreateDateRange(client, fromDate, toDate, page, itemsPerPage) {
        var fromDateIso = (fromDate instanceof moment) ? fromDate.toISOString() : moment(fromDate).toISOString();
        var toDateIso = (toDate instanceof moment) ? toDate.toISOString() : moment(toDate).toISOString();

        return api.GetAllShipmentListItemsByCreateDateRange({
            clientGuid: client.clientGuid,
            from: fromDateIso,
            to: toDateIso,
            page: page,
            itemsPerPage: itemsPerPage
        });
    }

    function getAllShipmentListItemsByCreateDateRangeCount(client, fromDate, toDate, itemsPerPage) {
        var fromDateIso = (fromDate instanceof moment) ? fromDate.toISOString() : moment(fromDate).toISOString();
        var toDateIso = (toDate instanceof moment) ? toDate.toISOString() : moment(toDate).toISOString();

        return api.GetAllShipmentListItemsByCreateDateRangeCount({
            clientGuid: client.clientGuid,
            from: fromDateIso,
            to: toDateIso,
            itemsPerPage: itemsPerPage
        });
    }

    function getAllShipmentListItemsByCreateDateForClientRange(client, fromDate, toDate, page, itemsPerPage) {
        var fromDateIso = (fromDate instanceof moment) ? fromDate.toISOString() : moment(fromDate).toISOString();
        var toDateIso = (toDate instanceof moment) ? toDate.toISOString() : moment(toDate).toISOString();

        return api.GetAllShipmentListItemsByCreateDateForClientRange({
            clientGuid: client.id,
            from: fromDateIso,
            to: toDateIso,
            page: page,
            itemsPerPage: itemsPerPage
        });
    }

    function getAllShipmentListItemsByCreateDateForClientRangeCount(client, fromDate, toDate, itemsPerPage) {
        var fromDateIso = (fromDate instanceof moment) ? fromDate.toISOString() : moment(fromDate).toISOString();
        var toDateIso = (toDate instanceof moment) ? toDate.toISOString() : moment(toDate).toISOString();

        return api.GetAllShipmentListItemsByCreateDateForClientRangeCount({
            clientGuid: client.id,
            from: fromDateIso,
            to: toDateIso,
            itemsPerPage: itemsPerPage
        });
    }
}
